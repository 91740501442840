import React, { useEffect, useState } from 'react'
import LabelComponent from './LabelComponent/_index'
import ResultSearch from './ResultSearch/_index'
import { Container } from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { CorrespondenteBancarioContextProps, Partner, useCorrespondenteBancario } from 'src/context/CorrespondenteBancarioContext/CorrespondenteBancarioContext'

const SearchCorresponding = () => {
  const { partners }: CorrespondenteBancarioContextProps = useCorrespondenteBancario()

  const [ stateOptions, setStateOptions ] = useState<string[]>([])
  const [ cityOptions, setCityOptions ] = useState<string[]>([])
  const [ neighborhoodOptions, setNeighborhoodOptions ] = useState<string[]>([])

  const [ stateSelected, setStateSelected ] = useState<string>()
  const [ citySelected, setCitySelected ] = useState<string>()
  const [ neighborhoodSelected, setNeighborhoodSelected ] = useState<string>()

  const [ filteredPartners, setFilteredPartners ] = useState<Partner[]>([])

  useEffect(() => {
    const states = Object.keys(partners)
    setStateOptions(states)
  }, [ partners ])

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = event.target.value
    setStateSelected(selectedState)

    setCityOptions([])
    setCitySelected(undefined)

    setNeighborhoodOptions([])
    setNeighborhoodSelected(undefined)

    setFilteredPartners([])

    if (selectedState) {
      const cities = new Set<string>()
      partners[selectedState].forEach((entry: Partner) => {
        cities.add(entry.city)
      })
      setCityOptions(Array.from(cities))
    }
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCity = event.target.value

    setCitySelected(selectedCity)
    setNeighborhoodSelected(undefined)

    setFilteredPartners([])

    if (stateSelected && selectedCity) {
      const neighborhoods = new Set<string>()
      partners[stateSelected].forEach((entry: Partner) => {
        if (entry.city === selectedCity) {
          neighborhoods.add(entry.neighborhood)
        }
      })
      setNeighborhoodOptions(Array.from(neighborhoods))
    } else {
      setNeighborhoodOptions([])
    }
  }

  const handleNeighborhoodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNeighborhoodSelected(event.target.value)
    setFilteredPartners([])
  }

  const handleSearch = () => {
    if (stateSelected && citySelected && neighborhoodSelected) {
      const filtered = partners[stateSelected]?.filter((partner: Partner) =>
        partner.city === citySelected && partner.neighborhood === neighborhoodSelected
      ) || []
      setFilteredPartners(filtered)
    }
  }

  return (
    <section>
      <Container>
        <div className='row mt-md-5 align-items-center'>
          <div className='col-12 col-md-6 col-lg-5'>

            <LabelComponent text='Estado' htmlFor='estado' />
            <span className='select mb-3'>
              <select
                value={stateSelected}
                onChange={handleStateChange}
                className={stateSelected ? 'selected' : ''}
              >
                <option value='' className='text-grayscale--300'>Selecione o estado</option>
                {stateOptions?.map((state: string) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </span>

            <LabelComponent text='Cidade' htmlFor='cidade' />
            <span className='select mb-3'>
              <select
                value={citySelected}
                onChange={handleCityChange}
                className={citySelected ? 'selected' : ''}
              >
                <option className='text-grayscale--300'>Selecione a cidade</option>
                {cityOptions?.map((cityName: string) => (
                  <option key={cityName} value={cityName}>
                    {cityName}
                  </option>
                ))}
              </select>
            </span>

            <LabelComponent text='Bairro' htmlFor='bairro' />
            <span className='select'>
              <select
                value={neighborhoodSelected}
                onChange={handleNeighborhoodChange}
                className={neighborhoodSelected ? 'selected' : ''}
              >
                <option className='text-grayscale--300'>Selecione o bairro</option>
                {neighborhoodOptions?.map((name: string) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </span>

            <div className='mt-5'>
              <button
                onClick={handleSearch}
                type='submit'
                className='btn text-white bg-orange--linear w-100'
                title='Enviar'
                disabled={!stateSelected || !citySelected || !neighborhoodSelected}
              >
                Buscar correspondentes
              </button>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 mt-5 mt-md-0'>
            {filteredPartners?.length > 0 ? (
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{ items: 1 }}
                lg={{ items: 1 }}
                xl={{ items: 1 }}
              >
                {filteredPartners?.map((item: Partner) => (
                  <ResultSearch
                    key={item?.cnpj}
                    corresponding={item}
                  />
                ))}
              </DefaultCarousel>
            ) : (
              <ResultSearch />
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SearchCorresponding
